import { Core } from "./api/cores";
import { TraceVariable } from "./api/nodes";
import { Task, TaskStatus } from "./api/tasks";
import { ObjectValues } from "./utilities";

export const TaskEventE = {
	TaskStarted: "task-started",
	TaskCompleted: "task-completed",
	TaskUpdated: "task-updated",
} as const;
export type TaskEvent = ObjectValues<typeof TaskEventE>;

export type TaskBusEvent = { type: TaskEvent; taskData: Task };

export type FileTree = {
	children: FileTree[];
	name: string;
	path: string;
	type: string;
};

export type RepositoryWorkingBranch = {
	name: string;
	commit: {
		sha: string;
	};
};

export const ResultsPanelTabNamesE = {
	Stdout: "stdout",
	Stderr: "stderr",
	Build: "Build",
	Internals: "internals",
	Stats: "Runtime Information",
	Files: "files",
} as const;
type ResultsPanelTabNames = (typeof ResultsPanelTabNamesE)[keyof typeof ResultsPanelTabNamesE];

export const ResultsPanelTabCodesE = {
	Stdout: "Stdout",
	Stderr: "Stderr",
	Build: "Build",
	Internals: "Internals",
	Stats: "Stats",
	Files: "Files",
} as const;
type ResultsPanelTabCodes = (typeof ResultsPanelTabCodesE)[keyof typeof ResultsPanelTabCodesE];

export type ResultsPanelTab = {
	name: ResultsPanelTabNames;
	code: ResultsPanelTabCodes;
	icon: string;
	disabled: boolean;
	code_tag: boolean;
};

// type repo = {
// 	Object: "Repository";
// 	RepositoryID: string;
// 	Owner: string;
// 	RemoteURL: string;
// 	Commit: string;
// 	Branch: string;
// 	BuildDirectory: string;
// 	Arguments: string;
// 	CreatedAt: number;
// 	UpdatedAt: number;
// };

// "Task": {
// 	"Object": "Pipeline",
// 	"PipelineID": "pip_b621bfe15c104f5183cd27895e04916f",
// }

export type TextContent = {
	type: "text";
	body: string;
};

export type ImageV1Content = {
	type: "image";
	version: "v1";
	body: { ImageURL: undefined | string };
};

export type ImageV2Content = {
	type: "image";
	version: "v2";
	body: {
		Value: string;
		ImageURL: undefined | string;
	};
};
export type ImageV3Content = {
	type: "image";
	version: "v3";
	body: {
		Value: string;
		ValueID: undefined | string;
		ImageURL?: undefined | string;
		PlotLoading?: undefined | boolean;
	};
}
export type ImageV4Content = {
	type: "image";
	version: "v4";
	body: {
		Value: string;
		ValueID?: undefined | string;
		UxString?: undefined | string;
		ImageURL?: undefined | string;
		PlotLoading?: undefined | boolean;
	};
};

export type ImageContent = ImageV1Content | ImageV2Content | ImageV3Content | ImageV4Content;
export type TransformedTabContent = TextContent | ImageContent;

export type RemoteAccessState = {
	loading: boolean;
	status?: TaskStatus;
	error: boolean;
	message: string;
};

export type RepoConnectionConfig = {
	remoteURL: URL;
	// full_name: string;
};

export type SignaloidConfigFile = {
	MinimumCore: Partial<Core>;
	TraceVariables: TraceVariable[];
};

export const PlatformAuthStateE = {
	InitialisingAuthUser: "InitialisingAuthUser",
	NoAuthUser: "NoAuthUser",
	InitialisingUserSubscriptions: "InitialisingUserSubscriptions",
	NoUserSubscription: "NoUserSubscription",
	Signedin: "Signedin",
	Error: "Error",
} as const;
export type PlatformAuthState = (typeof PlatformAuthStateE)[keyof typeof PlatformAuthStateE];

export const PlatformGlobalStateE = {
	WaitingForLogin: "WaitingForLogin",
	InitialisingUserAuth: "InitialisingUserAuth",
	Initialising: "Initialising",
	OnboardingMode: "OnboardingMode",
	Ready: "Ready",
} as const;
export type PlatformGlobalState = (typeof PlatformGlobalStateE)[keyof typeof PlatformGlobalStateE];

export const SignaloidConfigFileMinimumCoreOptions = {
	Class: { type: "option", options: ["C0"] },
	Precision: { type: "number", min: 4, max: 100000 },
	MemorySize: { type: "number", min: 256000, max: 4000000000 },
	Microarchitecture: { type: "option", options: ["Athens", "Zurich", "Bypass", "Reference"] },
	CorrelationTracking: { type: "option", options: ["Autocorrelation", "Disable"] },
} as const;
