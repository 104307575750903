import { defineStore } from "pinia";
import localForage from "localforage";
import axios from "axios";
import * as signaloidClient from "@/js/signaloidClient";
import pako from "pako";
import { parseTaskStatsObjectToStatsTabString, transformTabContent } from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";
import { useTasksStore } from "./tasks";
import { plotUxValue, plotValue } from "@/js/signaloidClient";
import { isTaskTerminated } from "@/types/api/tasks";

interface TaskOutput {
	build: string;
	stderr: string;
	stdout: string;
	stdoutChunks: string[];
	timeFetchedUnix: number;
}

interface PlotOutput {
	presignedURL: string;
	timeFetchedUnix: number;
}

interface ChunkMetadata {
	totalChunks: number;
	chunksInCache: number;
}

interface AccessTimes {
	[taskID: string]: number;
}

localForage.config({
	name: "taskOutputs",
	storeName: "outputs",
});

const LRU_TRACKING_KEY = "taskAccessTimes";
const LRU_TRACKING_KEY_PLOTS = "plotAccessTimes";
const outputUrlsExpireTimeInMinutes = 50;
const plotsUrlsExpireTimeInMinutes = 20;
const numberOfTasksToKeepAtStorage = 5;
const numberOfPlotsToKeepAtStorage = 15;

export const useTaskOutputsStore = defineStore("taskOutputs", {
	state: () => ({
		// State to keep track of fetched outputs
		outputsUrls: {} as { [taskId: string]: TaskOutput },
		// State to manage in-memory chunks for current view
		stdoutChunkMetadata: {} as { [taskId: string]: ChunkMetadata },
		// State to keep loading while fetching and transforming data.
		outputIsLoadingState: {} as { [taskId: string]: boolean },
		// This is what we show on panel
		stdoutStringStore: {} as { [taskId: string]: string },
		stderrStringStore: {} as { [taskId: string]: string },
		buildStringStore: {} as { [taskId: string]: string },
		statsStringStore: {} as { [taskId: string]: string },
	}),
	getters: {
		getStringStdout: (state) => (taskID?: string) => {
			const stringStdout = state.stdoutStringStore[taskID ?? ""];
			if (!stringStdout) {
				return "";
			}
			return stringStdout;
		},
		getStringStderr: (state) => (taskID?: string) => {
			const stringStderr = state.stderrStringStore[taskID ?? ""];
			if (!stringStderr) {
				return "";
			}
			return stringStderr;
		},
		getStringBuild: (state) => (taskID?: string) => {
			const stringBuild = state.buildStringStore[taskID ?? ""];
			if (!stringBuild) {
				return "";
			}
			return stringBuild;
		},
		getStringStats: (state) => (taskID?: string) => {
			const stringStats = state.statsStringStore[taskID ?? ""];
			if (!stringStats) {
				return "";
			}
			return stringStats;
		},
		getTransformedStdout: (state) => (taskID?: string) => {
			const stringStdout = state.stdoutStringStore[taskID ?? ""];
			if (!stringStdout) {
				return transformTabContent("");
			}
			return transformTabContent(stringStdout);
		},
		getTransformedStderr: (state) => (taskID?: string) => {
			const stringStderr = state.stderrStringStore[taskID ?? ""];
			if (!stringStderr) {
				return transformTabContent("");
			}
			return transformTabContent(stringStderr);
		},
		getTransformedBuild: (state) => (taskID?: string) => {
			const stringBuild = state.stderrStringStore[taskID ?? ""];
			if (!stringBuild) {
				return transformTabContent("");
			}
			return transformTabContent(stringBuild);
		},
		getTransformedStats: (state) => (taskID?: string) => {
			const stringStats = state.buildStringStore[taskID ?? ""];
			if (!stringStats) {
				return transformTabContent("");
			}
			return transformTabContent(stringStats);
		},

		getStdoutHasMoreChunks: (state) => (taskID: string) => {
			const chunkMetadata = state.stdoutChunkMetadata[taskID];
			if (!chunkMetadata) {
				return false;
			}
			if (chunkMetadata.chunksInCache < chunkMetadata.totalChunks) {
				return true;
			}
			return false;
		},

		getTaskLoadingState: (state) => (taskID: string) => {
			return state.outputIsLoadingState[taskID] ?? false;
		},
	},
	actions: {
		async fetchTaskOutputMetadata(taskId: string): Promise<TaskOutput> {
			const outputStreams = await signaloidClient.getTaskOutputURLs(taskId);
			return {
				build: outputStreams.data.Build,
				stderr: outputStreams.data.Stderr,
				stdout: outputStreams.data.Stdout,
				stdoutChunks: outputStreams.data.StdoutChunks,
				timeFetchedUnix: Date.now(),
			};
		},
		async loadBuildOutput(taskID: string, taskOutput: TaskOutput) {
			const stringBuildStoredInCache = await localForage.getItem<string>(`${taskID}-build`);
			if (stringBuildStoredInCache != null) {
				const updatedStore = { ...this.buildStringStore, [taskID]: stringBuildStoredInCache };
				this.buildStringStore = updatedStore;
				return;
			}
			let buildResponse: string;
			try {
				const response = await axios.get(taskOutput.build);
				buildResponse = response.data;
			} catch (error) {
				console.error(error);
				buildResponse = "";
			}
			await localForage.setItem(`${taskID}-build`, buildResponse);
			const updatedStore = { ...this.buildStringStore, [taskID]: buildResponse };
			this.buildStringStore = updatedStore;
		},
		async loadStderrOutput(taskID: string, taskOutput: TaskOutput) {
			const stringStderrStoredInCache = await localForage.getItem<string>(`${taskID}-stderr`);
			if (stringStderrStoredInCache != null) {
				const updatedStore = { ...this.stderrStringStore, [taskID]: stringStderrStoredInCache };
				this.stderrStringStore = updatedStore;
				return;
			}
			let stderrResponse: string;
			try {
				const response = await axios.get(taskOutput.stderr);
				stderrResponse = response.data;
			} catch (error) {
				console.error(error);
				stderrResponse = "";
			}
			await localForage.setItem(`${taskID}-stderr`, stderrResponse);
			const updatedStore = { ...this.stderrStringStore, [taskID]: stderrResponse };
			this.stderrStringStore = updatedStore;
		},
		async loadStatsOutput(taskID: string) {
			const stringStatsStoredInCache = await localForage.getItem<string>(`${taskID}-stats`);
			if (stringStatsStoredInCache != null) {
				const updatedStore = { ...this.buildStringStore, [taskID]: stringStatsStoredInCache };
				this.statsStringStore = updatedStore;
			} else {
				const tasksStore = useTasksStore();
				const taskData = tasksStore.getTaskByID(taskID);
				const statsString = parseTaskStatsObjectToStatsTabString(taskData?.Stats);
				await localForage.setItem(`${taskID}-stats`, statsString);

				const updatedStore = { ...this.statsStringStore, [taskID]: statsString };
				this.statsStringStore = updatedStore;
			}
		},
		async loadStdoutOutput(taskID: string, taskOutput: TaskOutput) {
			const chunkMetadata = await localForage.getItem<ChunkMetadata>(`${taskID}-stdout-metadata`);
			const stringStdoutStoredInCache = await localForage.getItem<string>(`${taskID}-stdout`);

			const resultsInCache = chunkMetadata != null && stringStdoutStoredInCache != null;
			if (resultsInCache) {
				const updatedStoreChunk = { ...this.stdoutChunkMetadata, [taskID]: chunkMetadata };
				this.stdoutChunkMetadata = updatedStoreChunk;
				const updatedStore = { ...this.stdoutStringStore, [taskID]: stringStdoutStoredInCache };
				this.stdoutStringStore = updatedStore;
				return;
			}

			const noChunksFound = !taskOutput.stdoutChunks;
			if (noChunksFound) {
				let stdoutChunkResponse: string = "";
				try {
					const response = await axios.get(taskOutput.stdout);
					stdoutChunkResponse = response.data;
				} catch (error) {
					console.error(error);
				}
				await localForage.setItem(`${taskID}-stdout`, stdoutChunkResponse);

				const newChunk = {
					totalChunks: 1,
					chunksInCache: 1,
				} as ChunkMetadata;
				await localForage.setItem(`${taskID}-stdout-metadata`, newChunk);

				const updatedStoreChunk = { ...this.stdoutChunkMetadata, [taskID]: newChunk };
				this.stdoutChunkMetadata = updatedStoreChunk;

				const updatedStore = { ...this.stdoutStringStore, [taskID]: stdoutChunkResponse };
				this.stdoutStringStore = updatedStore;
				return;
			}
			const compressedChunksFound =
				taskOutput.stdoutChunks[0].includes(".gzip") || taskOutput.stdoutChunks[0].includes(".gz");

			let stdOutChunk: string = "";
			if (compressedChunksFound) {
				try {
					const response = await axios.get(taskOutput.stdoutChunks[0], {
						responseType: "arraybuffer", // Ensure you're fetching the data as a binary array buffer
					});
					const decompressed = pako.inflate(new Uint8Array(response.data)); // Decompress to get Uint8Array
					stdOutChunk = new TextDecoder("utf-8").decode(decompressed);
				} catch (e) {
					console.error("Failed to decode decompressed data as UTF-8", e);
				}
			} else {
				try {
					const stdoutChunkResponse = await axios.get(taskOutput.stdoutChunks[0]);
					stdOutChunk = stdoutChunkResponse.data;
				} catch (e) {
					console.error("Failed to fetch decompressed data as UTF-8", e);
				}
			}
			await localForage.setItem(`${taskID}-stdout`, stdOutChunk);

			const newChunk = {
				totalChunks: taskOutput.stdoutChunks.length,
				chunksInCache: 1,
			} as ChunkMetadata;
			await localForage.setItem(`${taskID}-stdout-metadata`, newChunk);

			const updatedStoreChunk = { ...this.stdoutChunkMetadata, [taskID]: newChunk };
			this.stdoutChunkMetadata = updatedStoreChunk;

			const updatedStore = { ...this.stdoutStringStore, [taskID]: stdOutChunk };
			this.stdoutStringStore = updatedStore;
		},
		// To be used if we want to load a task
		async loadOutputs(taskID: string) {
			try {
				const tasksStore = useTasksStore();
				const taskData = tasksStore.getTaskByID(taskID);

				if (!taskID || taskID == "") {
					return;
				}

				if (taskData?.Status && !isTaskTerminated(taskData.Status)) {
					return;
				}

				this.updateTaskAccessTime(taskID);
				const updatedStore = { ...this.outputIsLoadingState, [taskID]: true };
				this.outputIsLoadingState = updatedStore;

				const taskOutput = await this.getOutputURLs(taskID);

				const outputsPromises = [
					this.loadBuildOutput(taskID, taskOutput),
					this.loadStderrOutput(taskID, taskOutput),
					this.loadStatsOutput(taskID),
					this.loadStdoutOutput(taskID, taskOutput),
				];

				await Promise.all(outputsPromises);
			} catch (error) {
				console.log(error);
				monitoringCaptureError(error, "taskOutputs.loadOutputs");
			} finally {
				const updatedStore = { ...this.outputIsLoadingState, [taskID]: false };
				this.outputIsLoadingState = updatedStore;
			}
		},
		async fetchNextChunk(taskID: string) {
			try {
				this.updateTaskAccessTime(taskID);
				const updatedLoadingStore = { ...this.outputIsLoadingState, [taskID]: true };
				this.outputIsLoadingState = updatedLoadingStore;

				const chunkMetadata = this.stdoutChunkMetadata[taskID];
				const nextChunkAvailable = chunkMetadata.chunksInCache < chunkMetadata.totalChunks;
				if (!nextChunkAvailable) {
					return;
				}
				const taskOutput = await this.getOutputURLs(taskID);

				const isChunkCompressed =
					taskOutput.stdoutChunks[chunkMetadata.chunksInCache].includes(".gzip") ||
					taskOutput.stdoutChunks[chunkMetadata.chunksInCache].includes(".gz");

				let newChunkStdoutString: string = "";
				if (isChunkCompressed) {
					try {
						const response = await axios.get(taskOutput.stdoutChunks[chunkMetadata.chunksInCache], {
							responseType: "arraybuffer", // Ensure you're fetching the data as a binary array buffer
						});
						const decompressed = pako.inflate(new Uint8Array(response.data)); // Decompress to get Uint8Array
						newChunkStdoutString = new TextDecoder("utf-8").decode(decompressed);
					} catch (e) {
						console.error("Failed to decode decompressed data as UTF-8", e);
					}
				} else {
					try {
						const stdoutChunkResponse = await axios.get(
							taskOutput.stdoutChunks[chunkMetadata.chunksInCache]
						);
						newChunkStdoutString = stdoutChunkResponse.data;
					} catch (e) {
						console.error("Failed to fetch decompressed data as UTF-8", e);
					}
				}
				const oldStringStdoutStoredInCache = await localForage.getItem<string>(`${taskID}-stdout`);
				const newStdoutString = oldStringStdoutStoredInCache + newChunkStdoutString;
				await localForage.setItem(`${taskID}-stdout`, newStdoutString);
				await localForage.setItem(`${taskID}-stdout-metadata`, {
					totalChunks: taskOutput.stdoutChunks.length,
					chunksInCache: chunkMetadata.chunksInCache + 1,
				} as ChunkMetadata);

				const updatedStdoutStringStore = { ...this.stdoutStringStore, [taskID]: newStdoutString };
				this.stdoutStringStore = updatedStdoutStringStore;

				const newChunkMetadata = {
					totalChunks: taskOutput.stdoutChunks.length,
					chunksInCache: chunkMetadata.chunksInCache + 1,
				} as ChunkMetadata;
				const updatedChunkMetadataStore = { ...this.stdoutChunkMetadata, [taskID]: newChunkMetadata };
				this.stdoutChunkMetadata = updatedChunkMetadataStore;
			} catch (error) {
				console.log(error);
				monitoringCaptureError(error, "taskOutputs.fetchNextChunk");
			} finally {
				const updatedStore = { ...this.outputIsLoadingState, [taskID]: false };
				this.outputIsLoadingState = updatedStore;
			}
		},

		async getOutputURLs(taskID: string): Promise<TaskOutput> {
			const outputURLs = this.outputsUrls[taskID];
			if (!outputURLs) {
				const output = await this.fetchTaskOutputMetadata(taskID);
				this.outputsUrls[taskID] = output;
				return output;
			}
			const difference = Math.abs(outputURLs.timeFetchedUnix - Date.now());
			const minutesDifference = Math.floor(difference / 60000);

			const expired = minutesDifference >= outputUrlsExpireTimeInMinutes;

			if (expired) {
				const output = await this.fetchTaskOutputMetadata(taskID);
				this.outputsUrls[taskID] = output;
				return output;
			}
			return outputURLs;
		},

		async updateTaskAccessTime(taskID: string) {
			const accessTimes = (await localForage.getItem<AccessTimes>(LRU_TRACKING_KEY)) || {};
			accessTimes[taskID] = Date.now(); // Store current time as Unix timestamp
			await localForage.setItem(LRU_TRACKING_KEY, accessTimes);
		},
		// Clear in memory and tabs content
		async cleanUpTask(taskID: string) {
			await this.performLRUCleanup();
			delete this.stdoutStringStore[taskID];
			delete this.stderrStringStore[taskID];
			delete this.buildStringStore[taskID];
			delete this.statsStringStore[taskID];
			delete this.outputIsLoadingState[taskID];
		},

		async performLRUCleanup() {
			// Tasks object cleanup
			await this.taskObjectsCleanup();
			// Plots cleanup
			await this.plotObjectsCleanup();
		},
		async plotObjectsCleanup() {
			const accessPlotTimes = (await localForage.getItem<AccessTimes>(LRU_TRACKING_KEY_PLOTS)) || {};
			const plotIDs = Object.keys(accessPlotTimes);

			if (plotIDs.length <= numberOfPlotsToKeepAtStorage) return;

			plotIDs.sort((a, b) => accessPlotTimes[a] - accessPlotTimes[b]);
			const plotsToRemove = plotIDs.slice(0, -numberOfPlotsToKeepAtStorage);
			console.log({ plotIDs, plotsToRemove });
			for (const plotUniqueKey of plotsToRemove) {
				await localForage.removeItem(`plot-${plotUniqueKey}`);
				delete accessPlotTimes[plotUniqueKey];
			}

			await localForage.setItem(LRU_TRACKING_KEY_PLOTS, accessPlotTimes);
		},
		async taskObjectsCleanup() {
			const accessTimes = (await localForage.getItem<AccessTimes>(LRU_TRACKING_KEY)) || {};
			const taskIDs = Object.keys(accessTimes);

			if (taskIDs.length <= numberOfTasksToKeepAtStorage) return;

			taskIDs.sort((a, b) => accessTimes[a] - accessTimes[b]);
			const tasksToRemove = taskIDs.slice(0, -numberOfTasksToKeepAtStorage);

			for (const taskID of tasksToRemove) {
				await localForage.removeItem(`${taskID}-stdout-metadata`);
				await localForage.removeItem(`${taskID}-stdout`);
				await localForage.removeItem(`${taskID}-stderr`);
				await localForage.removeItem(`${taskID}-stats`);
				delete accessTimes[taskID];
			}

			await localForage.setItem(LRU_TRACKING_KEY, accessTimes);
		},
		async updatePlotAccessTime(uniquePlotKey: string) {
			const accessTimes = (await localForage.getItem<AccessTimes>(LRU_TRACKING_KEY_PLOTS)) || {};
			accessTimes[uniquePlotKey] = Date.now();
			await localForage.setItem(LRU_TRACKING_KEY_PLOTS, accessTimes);
		},
		async getUxStringPlot(uxString: string) {
			const uniquePlotKey = uxString;
			this.updatePlotAccessTime(uniquePlotKey);
			const cachedPlotURL = await localForage.getItem<PlotOutput>(`plot-${uniquePlotKey}`);
			if (cachedPlotURL) {
				const difference = Math.abs(cachedPlotURL.timeFetchedUnix - Date.now());
				const minutesDifference = Math.floor(difference / 60000);

				const expired = minutesDifference >= plotsUrlsExpireTimeInMinutes;
				if (!expired) {
					return cachedPlotURL.presignedURL;
				}
			}
			const resp = await plotUxValue(uxString);
			const plotOutput = {
				presignedURL: resp.data.presignedURL,
				timeFetchedUnix: Date.now(),
			} as PlotOutput;
			await localForage.setItem(`plot-${uniquePlotKey}`, plotOutput);
			return resp.data.presignedURL;
		},
		async getValueIdPlot(taskID: string, valueID: string) {
			const uniquePlotKey = taskID + valueID;
			this.updatePlotAccessTime(uniquePlotKey);
			const cachedPlotURL = await localForage.getItem<PlotOutput>(`plot-${uniquePlotKey}`);
			if (cachedPlotURL) {
				const difference = Math.abs(cachedPlotURL.timeFetchedUnix - Date.now());
				const minutesDifference = Math.floor(difference / 60000);

				const expired = minutesDifference >= plotsUrlsExpireTimeInMinutes;
				if (!expired) {
					return cachedPlotURL.presignedURL;
				}
			}
			const resp = await plotValue(taskID, valueID);
			const plotOutput = {
				presignedURL: resp.data.presignedURL,
				timeFetchedUnix: Date.now(),
			} as PlotOutput;
			await localForage.setItem(`plot-${uniquePlotKey}`, plotOutput);
			return resp.data.presignedURL;
		},
		async purgeCache() {
			try {
				const keys = await localForage.keys();
				const outputKeys = keys.filter(
					(key) =>
						key.includes("-stdout") ||
						key.includes("-stderr") ||
						key.includes("-build") ||
						key.includes("-stats") ||
						key.includes("plot-") ||
						key === LRU_TRACKING_KEY ||
						key === LRU_TRACKING_KEY_PLOTS
				);
				for (const key of outputKeys) {
					await localForage.removeItem(key);
				}
			} catch (error) {
				console.error("Failed to purge cache:", error);
			}

			this.$reset();
		},
	},
});
