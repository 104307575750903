import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export * from "./signaloidClient/cores";
export * from "./signaloidClient/dataSources";
export * from "./signaloidClient/integrations_github";
export * from "./signaloidClient/keys";
export * from "./signaloidClient/pipelines";
export * from "./signaloidClient/projects"; // * Deprecated
export * from "./signaloidClient/repositories";
export * from "./signaloidClient/subscriptions";
export * from "./signaloidClient/tasks";
export * from "./signaloidClient/users";

// Define a custom type that extends AxiosRequestConfig with retryCount
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
	retryCount?: number;
}

/*
 *	Signaloid API client.
 *
 *	Must set `Authorization` header to identity token of user.
 *	Default `sam local start-api` port is 3000 so it will clash with the
 *	backend unless overriden as `sam local start-api port --port 3001`.
 */
export const signaloidApiClient = axios.create({
	baseURL: process.env.VUE_APP_SIGNALOID_API_URL || "https://127.0.0.1:3001",
	timeout: 10000 /* 10 seconds */,
});

function registerAccessToken(accessToken: string) {
	backendClient.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
}

function registerIdToken(idToken: string) {
	signaloidApiClient.defaults.headers["Authorization"] = `Bearer ${idToken}`;
}

export async function registerTokens(accessToken: Promise<string>, idToken: Promise<string>) {
	registerAccessToken(await accessToken);
	registerIdToken(await idToken);
}

export const backendClient = axios.create({
	baseURL: process.env.VUE_APP_BACKEND_API_URL || "https://127.0.0.1:3000",
	timeout: 300000 /* 300 seconds */,
});

export function updateBackendClientBaseURL(newBaseURL: string) {
	backendClient.defaults.baseURL = newBaseURL;
}

async function rejectedRequestPromiseInterceptor(
	err: AxiosError,
	axiosInstance: AxiosInstance,
	refreshTokenCallback: CallableFunction
) {
	if (err?.response?.status === 401) {
		console.log("Intercepting '401 Unauthorized' response...");

		const config = err.config as CustomAxiosRequestConfig;

		if (config.retryCount == null) {
			config.retryCount = 0;
		}

		if (config.retryCount >= 3) {
			console.error("Retry limit reached");
			return Promise.reject(err);
		}

		config.retryCount += 1;

		const delayInMs = 100 * config.retryCount ?? 0;
		new Promise((resolve) => setTimeout(resolve, delayInMs));

		try {
			const token = await refreshTokenCallback();

			/*
			 *  Paranoid manual check of timestamps because
			 *  Auth.currentSession() does not always refresh.
			 */
			const unixTime = Math.floor(Date.now() / 1000);
			if (unixTime > token.getExpiration()) {
				console.error("User authorization expired");
				throw new Error("Unauthorized");
			}

			/*
			 *  There exists a valid session now.
			 *  Set the default header to the new(?) access token.
			 */
			const tokenString = token.getJwtToken();
			axiosInstance.defaults.headers["Authorization"] = `Bearer ${tokenString}`;
			if (config == undefined) {
				return Promise.reject(err);
			}

			// Ensure headers is initialized
			if (!config.headers) {
				config.headers = {};
			}

			config.headers["Authorization"] = `Bearer ${tokenString}`;
			/*
			 *  Retry the request that failed.
			 */
			return axiosInstance(config);
		} catch (reason) {
			console.error("User is not authenticated:", reason);
			throw new Error("Unauthorized");
		}
	}
	return Promise.reject(err);
}

function registerSignaloidApiTokenRefreshInterceptor(refreshIdTokenCallback: CallableFunction) {
	signaloidApiClient.interceptors.response.use(undefined, (err: AxiosError) =>
		rejectedRequestPromiseInterceptor(err, signaloidApiClient, refreshIdTokenCallback)
	);
}

function registerBackendTokenRefreshInterceptor(refreshAccessTokenCallback: CallableFunction) {
	backendClient.interceptors.response.use(undefined, (err: AxiosError) =>
		rejectedRequestPromiseInterceptor(err, backendClient, refreshAccessTokenCallback)
	);
}

export function registerRefreshTokenCallbacks(
	refreshAccessTokenCallback: CallableFunction,
	refreshIdTokenCallback: CallableFunction
) {
	registerSignaloidApiTokenRefreshInterceptor(refreshIdTokenCallback);
	registerBackendTokenRefreshInterceptor(refreshAccessTokenCallback);
}

/*
 * Stripe checkout
 */
export function getStripeCheckoutPublishableKey() {
	return signaloidApiClient.get("/checkout-pk");
}

export function postCheckoutSession(priceID: string) {
	return signaloidApiClient.post("/checkout-session", {
		priceId: priceID,
	});
}

export function postPortalSession() {
	return signaloidApiClient.post("/portal-session", "");
}

export function joinWaitlist(formContents: string) {
	return signaloidApiClient.post("/utils/waitlist", formContents);
}

/**
 * Get dynamic content.
 * @param contentID The ID of the dynamic content.
 * @returns {Promise<AxiosResponse<any>>}
 */
// TODO: Warning: Not listed in the docs. Is there an endpoint for this?
export function getDynamicContent(contentID: string): Promise<AxiosResponse<any>> {
	return backendClient.get(`/dynamic-content/${contentID}`);
}
