import { useUserStore } from "@/stores/user";

export const DataSourceTypeE = {
	Gateway: "Gateway",
	Bucket: "Bucket",
	Drive: "Drive",
	SignaloidCloudStorage: "SignaloidCloudStorage",
	DataSource: "DataSource",
} as const;

export type DataSourceType = (typeof DataSourceTypeE)[keyof typeof DataSourceTypeE];

export type DataSource = {
	Object: "DataSource";
	ResourceID: string;
	Location: string;
	ResourceType: Omit<DataSourceType, "Drive" | "DataSource">;
};

export type DataDrive = {
	Object: "Drive";
	Owner: string;
	DriveID: string;
	Name: string;
	CreatedAt: number;
	UpdatedAt: number;
	DataSources: DataSource[];
};

export function isDataSourceValid(dataSource: any): dataSource is DataSource {
	const resourceTypeValid =
		dataSource?.ResourceType &&
		(typeof dataSource.ResourceType === "string" || dataSource.ResourceType instanceof String) &&
		Object.values(DataSourceTypeE).indexOf(dataSource?.ResourceType) > -1;

	return dataSource?.ResourceID && dataSource?.Location && resourceTypeValid;
}
export function isLocalMountConfig(mountConfig: any): mountConfig is LocalMountConfig {
	const resourceTypeValid =
		mountConfig?.ResourceType &&
		(typeof mountConfig.ResourceType === "string" || mountConfig.ResourceType instanceof String) &&
		Object.values(DataSourceTypeE).indexOf(mountConfig?.ResourceType) > -1;

	return mountConfig?.ResourceID && mountConfig?.Location && resourceTypeValid;
}

export type LocalMountConfig = {
	ResourceID: string;
	Location: string;
	ResourceType: DataSourceType;
};

export type MountableDataSource = Omit<LocalMountConfig, "Location">;

// {
// 	dataSourceID: string;
// 	dataSourceType: DataSourceType;
// 	dataStreamMountLocation: string;
// };

export type AWSS3Bucket = {
	Object: "Bucket";
	BucketID: string;
	Owner: string;
	CreatedAt: number;
	UpdatedAt: number;
	Name: string;
	Account: string;
	MountPath: string;
	Read: boolean;
	Write: boolean;
};

// stepping stone to a more generic type
export type BucketConfig = {
	MountPath: string;
	Read: boolean;
	Write: boolean;
};

// type DataSourceTypeOptions = {
// 	text: string;
// 	value: DataSource;
// 	disabled: boolean;
// }

// export type AWSS3Bucket extends DataSource = {
// 	sourceType: "Bucket";
// 	accountID: string;
// 	bucketName: string;
// 	readPermission: true;
// 	writePermission: false;
// }
