import { CoreClass, CoreClassE, CoreMicroarchitectureE } from "@/types/api/cores";

export type CognitoAccountGroup =
	| "CustomerPortalUser"
	| "DeveloperTier"
	| "FreeTier"
	| "VirtualServerTier"
	| "ProTier"
	| "EnterpriseTier"
	| "AcademicTier"
	| "NO_TIER";

export type AccountGroupCode =
	| "free-tier"
	| "developer-tier"
	| "academic-research-tier"
	| "pro-tier"
	| "enterprise-tier"
	| "virtual-server-tier";

export function compareTierCodes(tier0: AccountGroupCode, tier1: AccountGroupCode): boolean {
	const tierList: AccountGroupCode[] = [
		"free-tier",
		"developer-tier",
		"academic-research-tier",
		"pro-tier",
		"enterprise-tier",
		"virtual-server-tier",
	];

	return tierList.indexOf(tier0) >= tierList.indexOf(tier1);
}

export type TierFeatureHighlight = {
	text: string;
	icon: string;
};

export const UserLimitsE = {
	DynamicInstructionCount: "DynamicInstructionCount",
	ConcurrentTaskCount: "ConcurrentTaskCount",
	CloudStorageBytes: "CloudStorageBytes",
	CloudStorageBytesMaximum: "CloudStorageBytesMaximum",
	DataDriveCount: "DataDriveCount",
	BucketCount: "BucketCount",
	GatewayCount: "GatewayCount",
	DataSourceCount: "DataSourceCount",
	TaskHistoryLength: "TaskHistoryLength",
	PlotCount: "PlotCount",
	CoreCount: "CoreCount",
	RepositoryCount: "RepositoryCount",
	TaskCount: "TaskCount",
	BuildSizeBytesMaximum: "BuildSizeBytesMaximum",
	CorePrecisionBits: "CorePrecisionBits",
	CoreMemorySizeBytesMaximum: "CoreMemorySizeBytesMaximum",
	ProCoreVariants: "ProCoreVariants",
	PipelineLength: "PipelineLength",
	APIAccessCount: "APIAccessCount",
	KeyCount: "KeyCount",
	DedicatedSingleTenancyPrivateServerOption: "DedicatedSingleTenancyPrivateServerOption",
	AllowedMicroArchitectures: "AllowedMicroArchitectures",
	AllowedCoreClasses: "AllowedCoreClasses",
	DynamicInstructionPricePerExtraBillion: "DynamicInstructionPricePerExtraBillion",
	CloudStoragePricePerExtraGib: "CloudStoragePricePerExtraGib",
	PlotPricePerExtraThousand: "PlotPricePerExtraThousand",
	CoreReferenceQuality: "CoreReferenceQuality",
	APIAccessPricePerExtraThousand: "APIAccessPricePerExtraThousand",
	AuditableZeroDataRetention: "AuditableZeroDataRetention",
};
export type UserLimits = (typeof UserLimitsE)[keyof typeof UserLimitsE];
/*
 * 'undefiled' allowance ==> "no limit"
 * 'null' allowance => "not allowed"
 */
export type AccountTierDetail = {
	title: string;
	fullTitle: string;
	code: AccountGroupCode;
	monthlyModeSubtitles: string[];
	yearlyModeSubtitles: string[];
	shortVersionPoints: TierFeatureHighlight[];
	longVersionPoints: TierFeatureHighlight[];
	buttonText: string;
	requiresPayment: boolean;
	monthlySubscriptionStripePriceId?: string;
	yearlySubscriptionStripePriceId?: string;
	mustContactUs: boolean;
	allowance: {
		DynamicInstructionCount: number;
		ConcurrentTaskCount: number | null | undefined;
		CloudStorageBytes: number | null | undefined;
		DataDriveCount: number | null | undefined;
		BucketCount: number | null | undefined;
		GatewayCount: number | null | undefined;
		DataSourceCount: number | null | undefined;
		TaskCount: number | null | undefined;
		PlotCount: number | null | undefined;
		CoreCount: number | null | undefined;
		RepositoryCount: number | null | undefined;
		MaximumAttachedProcessorRam: number;
		MaxPrecisionBits: number;
		MaxPipelineLength: number;
		MaxReferenceQuality: number;
		TaskHistoryLength: number | undefined;
		AllowedMicroArchitectures: string[];
		AllowedCoreClasses: CoreClass[];
		APIAccessCount: number;
		KeyCount: number;
	};
};
export const tiers: AccountTierDetail[] = [
	{
		title: "Free",
		fullTitle: "Free Tier",
		code: "free-tier",
		monthlyModeSubtitles: ["£0 per month"],
		yearlyModeSubtitles: ["£0 per year"],
		shortVersionPoints: [
			{
				text: "500 million dynamic instructions per month.",
				icon: "checkmark",
			},
			{
				text: "100 distribution plots per month.",
				icon: "checkmark",
			},
		],
		longVersionPoints: [
			{
				text: "500 million Signaloid C0 processor instruction executions per month.",
				icon: "checkmark",
			},
			{
				text: "10MB of Signaloid cloud storage.",
				icon: "checkmark",
			},
			{ text: "Four C0 microarchitectures.", icon: "checkmark" },
			{ text: "64 maximum C0 processor precision.", icon: "checkmark" },
			{ text: "32 maximum reference execution quality.", icon: "checkmark" },
			{ text: "64MB maximum attached processor RAM.", icon: "checkmark" },
			{
				text: "100 distribution plots per month.",
				icon: "checkmark",
			},
			{ text: "Connect one data streaming gateway.", icon: "checkmark" },
			{ text: "GitHub integration.", icon: "checkmark" },
			{ text: "Connect one GitHub repository.", icon: "checkmark" },

			{ text: "One custom C0 processor core.", icon: "checkmark" },
			{ text: "Maximum application Unix-like pipeline length of two.", icon: "checkmark" },
			{ text: "Access to the last four tasks.", icon: "checkmark" },
		],
		buttonText: "Continue with Free Tier",
		requiresPayment: false,
		mustContactUs: false,
		allowance: {
			DynamicInstructionCount: 500_000_000,
			ConcurrentTaskCount: 1,
			CloudStorageBytes: 10_000_000,
			DataDriveCount: 1,
			BucketCount: 0,
			GatewayCount: 1,
			DataSourceCount: 1,
			TaskCount: undefined,
			PlotCount: 100,
			CoreCount: 1,
			RepositoryCount: 1,
			MaximumAttachedProcessorRam: 64_000_000,
			MaxPrecisionBits: 64,
			MaxPipelineLength: 2,
			MaxReferenceQuality: 32,
			AllowedMicroArchitectures: [
				CoreMicroarchitectureE.Zurich,
				CoreMicroarchitectureE.Athens,
				CoreMicroarchitectureE.Bypass,
				CoreMicroarchitectureE.Reference,
			],
			AllowedCoreClasses: [CoreClassE.C0],
			TaskHistoryLength: 4,
			APIAccessCount: 50,
			KeyCount: 1,
		},
	},
	{
		title: "Developer",
		fullTitle: "Developer Tier",
		code: "developer-tier",
		monthlyModeSubtitles: ["£8 per month"],
		yearlyModeSubtitles: ["£88 per year"],
		shortVersionPoints: [
			{
				text: "100 billion dynamic instructions per month.",
				icon: "plus",
			},
			{
				text: "10K distribution plots per month.",
				icon: "plus",
			},
			{ text: "All the features of the Free Tier.", icon: "checkmark" },
		],
		longVersionPoints: [
			{
				text: "100 billion Signaloid C0 processor instruction executions per month.",
				icon: "checkmark",
			},
			{
				text: "1GB of Signaloid cloud storage.",
				icon: "checkmark",
			},
			{ text: "Six C0 microarchitectures.", icon: "checkmark" },
			{ text: "1024 maximum C0 processor precision.", icon: "checkmark" },
			{ text: "1024 maximum reference execution quality.", icon: "checkmark" },
			{ text: "1GB maximum attached processor RAM.", icon: "checkmark" },
			{
				text: "10K distribution plots per month.",
				icon: "checkmark",
			},
			{ text: "Connect four data streaming gateways.", icon: "checkmark" },
			{ text: "GitHub integration.", icon: "checkmark" },
			{ text: "Connect 16 GitHub repositories.", icon: "checkmark" },

			{ text: "Eight custom C0 processor cores.", icon: "checkmark" },
			{ text: "Maximum application Unix-like pipeline length of eight.", icon: "checkmark" },
			{ text: "Access to the last 32 tasks.", icon: "checkmark" },
			{ text: "Run 16 concurrent tasks.", icon: "plus" },
			{ text: "High-performance Signaloid C0 Pro core variants.", icon: "plus" },
			// { text: "AWS integration.", icon: "plus" },
			{ text: "Connect two AWS S3 buckets.", icon: "plus" },
		],
		buttonText: "Subscribe",
		requiresPayment: true,
		// stripePriceId:  'price_1IvNcbB15W8cULvlIJs59OMK',
		monthlySubscriptionStripePriceId:
			process.env.VUE_APP_STRIPE_ENV === "live"
				? /* live price: */ "price_1Itrr7B15W8cULvl549ZVcpe"
				: /* test price: */ "price_1IvNcbB15W8cULvlIJs59OMK",
		yearlySubscriptionStripePriceId:
			process.env.VUE_APP_STRIPE_ENV === "live"
				? /* live price: */ "price_1ObK1bB15W8cULvl5vgnoaeC"
				: /* test price: */ "price_1ObK2mB15W8cULvlRTM37ouj",
		mustContactUs: false,
		allowance: {
			DynamicInstructionCount: 100_000_000_000,
			ConcurrentTaskCount: 16,
			CloudStorageBytes: 1_000_000_000,
			DataDriveCount: 16,
			BucketCount: 2,
			GatewayCount: 4,
			DataSourceCount: 16,
			TaskCount: undefined,
			PlotCount: 10_000,
			CoreCount: 8,
			RepositoryCount: 16,
			MaximumAttachedProcessorRam: 1_024_000_000,
			MaxPrecisionBits: 1024,
			MaxPipelineLength: 8,
			MaxReferenceQuality: 1024,
			AllowedMicroArchitectures: [
				CoreMicroarchitectureE.Zurich,
				CoreMicroarchitectureE.Athens,
				CoreMicroarchitectureE.Bypass,
				CoreMicroarchitectureE.Reference,
				CoreMicroarchitectureE.Jupiter,
			],
			AllowedCoreClasses: [CoreClassE.C0, CoreClassE.C0Pro],
			TaskHistoryLength: 32,
			APIAccessCount: 50,
			KeyCount: 1,
		},
	},
	// {
	// 	title: "Academic",
	// 	fullTitle: "Academic Tier",
	// 	code: "academic-research-tier",
	// 	subtitles: ["£0 per month"],
	// 	points: [
	// 		"Cloud access to 10 million Signaloid C0 processor instruction executions per month.",
	// 		"1GB of Signaloid Cloud Storage.",
	// 		// 'Access pre-packaged S0 sensors.',
	// 		"Academic Research Terms of Service.",
	// 		"Help center access.",
	// 	],
	// 	buttonText: "Contact Us",
	// 	requiresPayment: false,
	// 	mustContactUs: true,
	// 	allowance: {
	// 		DynamicInstructionCount: 10_000_000,
	// 		ConcurrentTaskCount: 1,
	// 		CloudStorageBytes: 1_000_000_000,
	// 		DataDriveCount: 1,
	// 		BucketCount: 0,
	// 		GatewayCount: 1,
	// 		DataSourceCount: undefined,
	// 		TaskCount: undefined,
	// 		PlotCount: undefined,
	// 		CoreCount: 8,
	// 		RepositoryCount: undefined,
	// 		MaximumAttachedProcessorRam: 1_024_000_000,
	// 		MaxPrecisionBits: 1024,
	// 		MaxReferenceQuality: 1024,
	// 	},
	// },
	{
		title: "Developer+API",
		fullTitle: "Developer+API Tier",
		code: "pro-tier",
		monthlyModeSubtitles: ["£49 per month"],
		yearlyModeSubtitles: ["£539 per year"],

		shortVersionPoints: [
			{
				text: "API access to Signaloid Compute Engine.",
				icon: "plus",
			},
			{
				text: "10K API invocations per month.",
				icon: "plus",
			},
			{ text: "All the features of the Developer Tier.", icon: "checkmark" },
			// "API Usage Terms of Service.",
		],
		longVersionPoints: [
			{
				text: "100 billion Signaloid C0 processor instruction executions per month.",
				icon: "checkmark",
			},
			{
				text: "1GB of Signaloid cloud storage.",
				icon: "checkmark",
			},
			{ text: "Six C0 microarchitectures.", icon: "checkmark" },
			{ text: "1024 maximum C0 processor precision.", icon: "checkmark" },
			{ text: "1024 maximum reference execution quality.", icon: "checkmark" },
			{ text: "1GB maximum attached processor RAM.", icon: "checkmark" },
			{
				text: "10K distribution plots per month.",
				icon: "checkmark",
			},
			{ text: "Connect four data streaming gateways.", icon: "checkmark" },
			{ text: "GitHub integration.", icon: "checkmark" },
			{ text: "Connect 16 GitHub repositories.", icon: "checkmark" },

			{ text: "Eight custom C0 processor cores.", icon: "checkmark" },
			{ text: "Maximum application Unix-like pipeline length of eight.", icon: "checkmark" },
			{ text: "Access to the last 32 tasks.", icon: "checkmark" },
			{ text: "Run 16 concurrent tasks.", icon: "checkmark" },
			{ text: "High-performance Signaloid C0 Pro core variants.", icon: "checkmark" },
			// { text: "AWS integration.", icon: "checkmark" },
			{ text: "Connect two AWS S3 buckets.", icon: "checkmark" },
			{
				text: "10K API invocations per month.",
				icon: "plus",
			},
		],
		buttonText: "Subscribe",
		requiresPayment: true,
		monthlySubscriptionStripePriceId:
			process.env.VUE_APP_STRIPE_ENV === "live"
				? /* live price: */ "price_1ItrreB15W8cULvleOntFj45"
				: /* test price: */ "price_1IvNcBB15W8cULvlBtbi08qa",
		yearlySubscriptionStripePriceId:
			process.env.VUE_APP_STRIPE_ENV === "live"
				? /* live price: */ "price_1ObK8LB15W8cULvlnw5xjnbO"
				: /* test price: */ "price_1ObKDFB15W8cULvll1ykv7cF",
		mustContactUs: true,
		allowance: {
			DynamicInstructionCount: 100_000_000_000,
			ConcurrentTaskCount: 16,
			CloudStorageBytes: 1_000_000_000,
			DataDriveCount: 16,
			BucketCount: 2,
			GatewayCount: 4,
			DataSourceCount: 16,
			TaskCount: undefined,
			PlotCount: 10_000,
			CoreCount: 8,
			RepositoryCount: 16,
			MaximumAttachedProcessorRam: 1_024_000_000,
			MaxPrecisionBits: 1024,
			MaxPipelineLength: 8,
			MaxReferenceQuality: 1024,
			AllowedMicroArchitectures: [
				CoreMicroarchitectureE.Zurich,
				CoreMicroarchitectureE.Athens,
				CoreMicroarchitectureE.Bypass,
				CoreMicroarchitectureE.Reference,
				CoreMicroarchitectureE.Jupiter,
			],
			AllowedCoreClasses: [CoreClassE.C0, CoreClassE.C0Pro],
			TaskHistoryLength: 32,
			APIAccessCount: 10_000,
			KeyCount: 1_000,
		},
	},
	{
		title: "Enterprise",
		fullTitle: "Enterprise Tier",
		code: "enterprise-tier",
		monthlyModeSubtitles: ["Contact us for pricing"],
		yearlyModeSubtitles: ["Contact us for pricing"],
		shortVersionPoints: [
			{ text: "No upper limit on computation.", icon: "plus" },
			{ text: "Unlimited concurrent tasks.", icon: "plus" },
			{ text: "Access to all Signaloid Compute Engine cores.", icon: "plus" },
			{ text: "All the features of the API Tier.", icon: "checkmark" },
		],
		longVersionPoints: [
			{
				text: "10 Trillion Signaloid C0 processor instruction executions per month.",
				icon: "checkmark",
			},
			{
				text: "1TB of Signaloid cloud storage.",
				icon: "checkmark",
			},
			{ text: "Six C0 microarchitectures.", icon: "checkmark" },
			{ text: "8192 maximum C0 processor precision.", icon: "checkmark" },
			{ text: "1M maximum reference execution quality.", icon: "checkmark" },
			{ text: "2GB maximum attached processor RAM.", icon: "checkmark" },
			{
				text: "1M distribution plots per month.",
				icon: "checkmark",
			},
			{ text: "Connect unlimited data streaming gateways.", icon: "checkmark" },
			{ text: "GitHub integration.", icon: "checkmark" },
			{ text: "Connect 64 GitHub repositories.", icon: "checkmark" },

			{ text: "Eight custom C0 processor cores.", icon: "checkmark" },
			{ text: "Maximum application Unix-like pipeline length of 16.", icon: "checkmark" },
			{ text: "Access unlimited past tasks.", icon: "checkmark" },
			{ text: "Run unlimited concurrent tasks.", icon: "checkmark" },
			{ text: "High-performance Signaloid C0 Pro core variants.", icon: "checkmark" },
			// { text: "AWS integration.", icon: "checkmark" },
			{ text: "Connect unlimited AWS S3 buckets.", icon: "checkmark" },
			{
				text: "100K API invocations per month,",
				icon: "checkmark",
			},
			{ text: "Access to additional computation, data storage and rendered plots.", icon: "plus" },
			{ text: "Dedicated single-tenancy compute instances.", icon: "plus" },
			{ text: "White labelling for the Signaloid Cloud Developer Platform.", icon: "plus" },
			{
				text: "Examples of interactive web apps that take advantage of applications running on the Signaloid Cloud Compute Engine.",
				icon: "plus",
			},
			// "Enterprise Terms of Service.",
			// "Help center access.",
		],
		buttonText: "Contact Sales",
		requiresPayment: false,
		mustContactUs: true,
		allowance: {
			DynamicInstructionCount: 10_000_000_000_000,
			CloudStorageBytes: 1_000_000_000_000,
			DataDriveCount: 64,
			TaskCount: undefined,
			PlotCount: 1_000_000,
			CoreCount: 8,
			RepositoryCount: 64,
			MaximumAttachedProcessorRam: 2_048_000_000,
			MaxPrecisionBits: 8192,
			MaxPipelineLength: 8,
			MaxReferenceQuality: 1_000_000,
			AllowedMicroArchitectures: [
				CoreMicroarchitectureE.Zurich,
				CoreMicroarchitectureE.Athens,
				CoreMicroarchitectureE.Bypass,
				CoreMicroarchitectureE.Reference,
				CoreMicroarchitectureE.Jupiter,
			],
			AllowedCoreClasses: [CoreClassE.C0, CoreClassE.C0Pro],
			// unlimited
			TaskHistoryLength: undefined,
			BucketCount: undefined,
			ConcurrentTaskCount: undefined,
			GatewayCount: undefined,
			DataSourceCount: undefined,
			APIAccessCount: 100_000,
			KeyCount: 1_000,
		},
	},
];

export const userGroupFromTokenPayload = (groups: null | CognitoAccountGroup[]) => {
	if (groups?.includes("VirtualServerTier")) {
		return "VirtualServerTier";
	}
	if (groups?.includes("EnterpriseTier")) {
		return "EnterpriseTier";
	}
	if (groups?.includes("ProTier")) {
		return "ProTier";
	}
	if (groups?.includes("DeveloperTier")) {
		return "DeveloperTier";
	}
	if (groups?.includes("AcademicTier")) {
		return "AcademicTier";
	}
	if (groups?.includes("FreeTier")) {
		return "FreeTier";
	}
	if (groups?.includes("EnterpriseTier")) {
		return "EnterpriseTier";
	}
	return "";
};

export const tierFromCode = (code: AccountGroupCode): AccountTierDetail | undefined => {
	return tiers.find((e) => e.code === code);
};

export const groupCode = (group: any | CognitoAccountGroup): AccountGroupCode | "" => {
	switch (group) {
		case "FreeTier":
			return "free-tier";
		case "AcademicTier":
			// AcademicTier cognito group is represented as DeveloperTier on the frontend
			return "developer-tier";
		case "DeveloperTier":
			return "developer-tier";
		case "VirtualServerTier":
			return "virtual-server-tier";
		case "ProTier":
			return "pro-tier";
		case "EnterpriseTier":
			return "enterprise-tier";
		default:
			return "";
	}
};

export type TierLimitDisplayObject = {
	name: string;
	id: UserLimits;
	usage: number;
	limit: number;
	unit: string;
	quotaSuffix?: string | string[];
	hasNoLimit?: boolean;
};
