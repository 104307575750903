import { Pipeline } from "./pipelines";
import { ModulePipelineNode, RepositoryPipelineNode, SourceCodePipelineNode, TraceVariable } from "./nodes";

import { DataSource } from "./dataSources";
import { Repository } from "./repositories";
import { SourceCode } from "./sourceCode";
import { Core } from "./cores";
import { Module } from "./modules";

export const TaskStatusE = {
	Initialising: "Initialising",
	Accepted: "Accepted",
	Compiling: "Compiling", //deprecated
	Building: "Building",
	InProgress: "In Progress",
	Completed: "Completed",
	Cancelled: "Cancelled",
	Stopped: "Stopped",
	NotFoundError: "Task Not Found",
	Forbidden: "Task Rejected",
	Error: "Error",
	UpgradeAccount: "Upgrade Your Account",
	TierLimited: "Tier Limit Reached",
} as const;
export type TaskStatus = (typeof TaskStatusE)[keyof typeof TaskStatusE];

export const TaskTypeE = {
	SourceCode: "SourceCode",
	Repository: "Repository",
	Pipeline: "Pipeline",
	Module: "Module",
} as const;
export type TaskType = (typeof TaskTypeE)[keyof typeof TaskTypeE];

export type TaskStatusTransition = {
	Status: TaskStatus;
	Timestamp: number;
	Message?: string;
};

export function isTaskActive(taskStatus: TaskStatus): boolean {
	const activeStatuses = [
		TaskStatusE.Accepted,
		TaskStatusE.Initialising,
		TaskStatusE.Compiling,
		TaskStatusE.Building,
		TaskStatusE.InProgress,
	];
	return activeStatuses.some((s) => s === taskStatus);
}

export function isTaskTerminated(taskStatus: TaskStatus): boolean {
	const activeStatuses = [TaskStatusE.Completed, TaskStatusE.Cancelled, TaskStatusE.Stopped, TaskStatusE.Error];
	return activeStatuses.some((s) => s === taskStatus);
}

export function isTaskSuccess(taskStatus: TaskStatus): boolean {
	const activeStatuses = [TaskStatusE.Completed];
	return activeStatuses.some((s) => s === taskStatus);
}
export function isTaskFail(taskStatus: TaskStatus): boolean {
	const activeStatuses = [
		TaskStatusE.Cancelled,
		TaskStatusE.Stopped,
		TaskStatusE.Error,
		TaskStatusE.NotFoundError,
		TaskStatusE.Forbidden,
	];
	return activeStatuses.some((s) => s === taskStatus);
}

export function canFetchVariables(taskStatus: TaskStatus): boolean {
	// If the task is in any of the following states, we can possibly fetch variables
	const statusesToCheckFor = [
		TaskStatusE.InProgress,
		TaskStatusE.Completed,
		TaskStatusE.Cancelled,
		TaskStatusE.Stopped,
	];

	return statusesToCheckFor.some((s) => s === taskStatus) ?? false;
}

export type Override<CoreT extends string | Core = string> = {
	Core?: CoreT;
	DataSources?: DataSource[];
	TraceVariables?: TraceVariable[];
	Arguments?: string;
	[key: string]: any;
};

/*
 * Request types
 */

// Equivalent PipelineTaskGeneric<string, string, string>;
export type PipelineTaskRequest = PipelineTaskGeneric;

// Equivalent RepositoryPipelineNode<string, string>;
export type RepositoryTaskRequest = RepositoryPipelineNode;

export type ModuleTaskRequest = {
	Type: "Module";
	Module: string;
	Overrides?: Override;
};

export type SourceCodeTaskRequest = {
	Type: "SourceCode";
	SourceCode: SourceCode; // Not an ID since this is not stored in the database
	Overrides: Override; // Overrides are required for SourceCode (until we can default to user-level default)
};

export type TaskRequest = PipelineTaskRequest | RepositoryTaskRequest | ModuleTaskRequest | SourceCodeTaskRequest;

/*
 * Response types
 */

export type TaskStatistics = {
	DynamicInstructions: number;
	ProcessorTime: number;
};

export type Task = {
	Object: "Task";
	TaskID: string;
	Owner: string;
	Application: PipelineApplication | RepositoryApplication | SourceCodeApplication | ModuleApplication;
	Status: TaskStatus;
	StatusTransitions: TaskStatusTransition[];
	Stats?: TaskStatistics;
	StartedAt: number /* The time the Task.Status becomes "In Progress" */;
	UpdatedAt: number;
	CreatedAt: number /* The time the Task.Status becomes "Accepted" */;
	FinishedAt: number /* The time the Task.Status becomes "Completed", "Cancelled", or "Stopped" */;
};

export type PipelineTask = Task & {
	Application: PipelineApplication;
};
export type RepositoryTask = Task & {
	Application: RepositoryApplication;
};
export type SourceCodeTask = Task & {
	Application: SourceCodeApplication;
};

export function isPipelineTask(task: Task): task is PipelineTask {
	return task.Application.Type === TaskTypeE.Pipeline;
}
export function isRepositoryTask(task: Task): task is RepositoryTask {
	return task.Application.Type === TaskTypeE.Repository;
}
export function isSourceCodeTask(task: Task): task is SourceCodeTask {
	return task.Application.Type === TaskTypeE.SourceCode;
}

/*
 * Application types : These are the types that are used in the Task.Application field
 */

export type PipelineTaskGeneric<
	CoreT extends string | Core = string,
	RepositoryT extends string | Repository<CoreT> = string,
	PipelineT extends string | Pipeline<CoreT, RepositoryT> = string
> = {
	Type: "Pipeline";
	Pipeline: PipelineT;
};

export type PipelineApplication = PipelineTaskGeneric<Core, Repository<Core>, Pipeline<Core, Repository<Core>>>;

export type RepositoryApplication = RepositoryPipelineNode<Core, Repository<Core>>;

export type ModuleApplication = ModulePipelineNode<Core, Module>;

export type SourceCodeApplication = SourceCodePipelineNode<Core>;

export const TaskOutputStreamsE = {
	stdout: "stdout",
	stderr: "stderr",
	build: "build",
} as const;
export type TaskOutputStreams = (typeof TaskOutputStreamsE)[keyof typeof TaskOutputStreamsE];

type Mapped<T> = {
	[Property in keyof T]: string;
};
// FIXME: how can i make this dynamic from TaskOutputStreams
export type TaskOutput = {
	[key: string]: string;
};
