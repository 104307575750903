// Libraries
import { AWSS3Bucket, BucketConfig, DataDrive, DataSource } from "@/types/api/dataSources";
import { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
// Types
import { GetDrives200Response, GetFiles200Response } from "../signaloidClient.types";

// * Things

/**
 * List Thing content by its ID.
 *
 * @param thingID The ID of the Thing resource to get.
 * @returns {Promise<AxiosResponse<any>>} The Thing resource.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Things/operation/GetThing
 *
 */
export function getThingByID(thingID: string): Promise<AxiosResponse<any>> {
	return signaloidApiClient.get(`/things/${thingID}`);
}

/**
 * Get a list of all Thing resources owned by the current user.
 * @returns {Promise<AxiosResponse<{[key:string]:any}>>} Resolves to an object containing a list
 * with the items
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Things/operation/GetThings
 */
export function getUserThings(): Promise<AxiosResponse<{ [key: string]: any }>> {
	return signaloidApiClient.get(`/things`);
}

/**
 * Get a list of all Thing resources owned by the current user.
 * @returns {Promise<AxiosResponse<any>>} Resolves to an object containing a list with the items.
 *
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Things/operation/GetThingFiles
 */
export function getThingFiles(thingID: string): Promise<AxiosResponse<any>> {
	return signaloidApiClient.get(`/things/${thingID}/files`);
}

/*
 * Warning: Endpoint does not exist.
 *  // TODO: Warning: Not listed in the docs. Is there an endpoint for this?
 */
export function updateThingName(thingID: string, newName: string) {
	const payload = {
		Name: newName,
	};
	return signaloidApiClient.patch(`/things/${thingID}`, payload);
}

/**
 * Get a list of all Bucket owned by the current user.
 *
 * @returns {Promise<AxiosResponse<{ [key: string]: any }>>} Resolves to an object containing a list
 * with the items.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Buckets/operation/GetBuckets
 */
// TODO: Response examples for 404 and 500 are missing
export function getUserBuckets(): Promise<AxiosResponse<{ [key: string]: any }>> {
	return signaloidApiClient.get(`/buckets`);
}

/**
 * Create a new Bucket resource connecting an S3 bucket to this Signaloid Cloud
 * User. bucketConfig optional keys: "MountPath".
 *
 * @param {string} bucketName The globally-unique name of the S3 bucket.
 * @param {string} accountID The AWS Account ID the S3 bucket is a resource of.
 * @param {{[key:string]:any}} bucketConfig Optional fields for the Bucket object.
 * @returns {Promise<AxiosResponse<AWSS3Bucket>>} The axios request promise. Body resolves to the newly
 * created Bucket object if successful.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Buckets/operation/ConnectBucket
 */
// TODO: Response examples for 404 and 500 are missing
export function createBucket(
	bucketName: string,
	accountID: string,
	bucketConfig: Partial<{
		MountPath: any;
		Read: any;
		Write: any;
	}>
): Promise<AxiosResponse<AWSS3Bucket>> {
	const optionalFields: Partial<BucketConfig> = {};

	if (Object.prototype.hasOwnProperty.call(bucketConfig, "MountPath")) {
		optionalFields.MountPath = bucketConfig.MountPath;
	}
	if (Object.prototype.hasOwnProperty.call(bucketConfig, "Read")) {
		optionalFields.Read = bucketConfig.Read;
	}
	if (Object.prototype.hasOwnProperty.call(bucketConfig, "Write")) {
		optionalFields.Write = bucketConfig.Write;
	}
	const payload = {
		Name: bucketName,
		Account: accountID,
		...optionalFields,
	};
	return signaloidApiClient.post(`/buckets`, payload);
}

/**
 * List the content of a Bucket.
 *
 * @param {string} bucketID The identifier of the bucket.
 * @returns {Promise<AxiosResponse<AWSS3Bucket>>} The axios request promise. Body resolves to the Bucket
 * object that was found if successful.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Buckets/operation/GetBucket
 *
 */
export function getBucketByID(bucketID: string): Promise<AxiosResponse<AWSS3Bucket>> {
	return signaloidApiClient.get(`/buckets/${bucketID}`);
}

/**
 * Edit a Bucket resource.
 *
 * @param {string} bucketID The identifier of the bucket.
 * @param {{[key:string]:any}} bucketConfig Optional fields for the Bucket object.
 * @returns The axios request promise. Body resolves to `{"message" : "OK"}` if successful.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Buckets/operation/EditBucket
 */
export function editBucketByID(
	bucketID: string,
	bucketConfig: Partial<{
		Name: any;
		Account: any;
		MountPath: any;
		Read: any;
		Write: any;
	}>
): Promise<AxiosResponse<AWSS3Bucket>> {
	const optionalFields: Partial<BucketConfig> = {};

	if (Object.prototype.hasOwnProperty.call(bucketConfig, "MountPath")) {
		optionalFields.MountPath = bucketConfig.MountPath;
	}
	if (Object.prototype.hasOwnProperty.call(bucketConfig, "Read")) {
		optionalFields.Read = bucketConfig.Read;
	}
	if (Object.prototype.hasOwnProperty.call(bucketConfig, "Write")) {
		optionalFields.Write = bucketConfig.Write;
	}

	const payload = {
		...optionalFields,
	};
	return signaloidApiClient.patch(`/buckets/${bucketID}`, payload);
}

/**
 * Delete a Bucket resource.
 *
 * @param {string} bucketID The identifier of the bucket.
 * @returns {Promise<AxiosResponse>} The axios request promise.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Buckets/operation/DeleteBucket
 */
export function deleteBucketByID(bucketID: string): Promise<AxiosResponse> {
	return signaloidApiClient.delete(`/buckets/${bucketID}`);
}

// * Drives
/**
 * Get a list of all Drive resources for a user.
 *
 * @returns {Promise<AxiosResponse<DataDrive>>} Resolves to an object containing a list with the items.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/infrastructure/operation/getUserDrives
 */
export function getUserDrives(): Promise<AxiosResponse<GetDrives200Response>> {
	return signaloidApiClient.get(`/drives`);
}

/**
 * Create a new Drive resource.
 *
 * @param {string} driveName Human readable name for the drive.
 * @param {DataSource[]} dataSources Data source specified by the drive.
 * @returns {Promise<AxiosResponse<DataDrive>>} The axios request promise. Body resolves to the newly created
 * drive object if successful.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Drives/operation/GetUserDrives
 */
export function createDrive(driveName: string, dataSources: DataSource[]): Promise<AxiosResponse<DataDrive>> {
	const payload = {
		Name: driveName,
		DataSources: dataSources,
	};
	return signaloidApiClient.post(`/drives`, payload);
}

/**
 * List Drive properties.
 *
 * @param {string} driveID The identifier of the drive.
 * @returns {Promise<AxiosResponse<DataDrive>>} The axios request promise. Body resolves to the Drive object
 * that was found if successful.`
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Drives/operation/GetDrive
 */
export function getDriveByID(driveID: string): Promise<AxiosResponse<DataDrive>> {
	return signaloidApiClient.get(`/drives/${driveID}`);
}

/**
 * Edit a Drive resource.
 *
 * @param {string} driveID Human readable name for the drive.
 * @param {{[key:string]:any}} driveConfig Optional fields for the Drive object.
 * @returns {Promise<AxiosResponse<DataDrive>>} The axios request promise. Body resolves to `{"message" : "OK"}`
 * if successful.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Drives/operation/EditDrive
 */
export function editDriveByID(driveID: string, driveConfig: Partial<DataDrive>): Promise<AxiosResponse<DataDrive>> {
	const optionalFields: Partial<DataDrive> = {};
	if (Object.prototype.hasOwnProperty.call(driveConfig, "Name")) {
		optionalFields.Name = driveConfig.Name;
	}
	if (Object.prototype.hasOwnProperty.call(driveConfig, "DataSources")) {
		optionalFields.DataSources = driveConfig.DataSources;
	}

	const payload = {
		...optionalFields,
	};
	return signaloidApiClient.patch(`/drives/${driveID}`, payload);
}

/**
 * Delete a Drive resource.
 *
 * @param {string} driveID
 * @returns {Promise<AxiosResponse>} The axios request promise.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Drives/operation/DeleteDrive
 */
export function deleteDriveByID(driveID: string): Promise<AxiosResponse> {
	return signaloidApiClient.delete(`/drives/${driveID}`);
}

// * Files (Signaloid Cloud Storage)

/**
 * Download a file or list a directory.
 *
 * @param {string} path A POSIX relative path of a directory. Must end with trailing slash. If empty
 * string, lists all files.
 * @returns {Promise<AxiosResponse<{[key:string]:any}>>} The axios request promise. Body resolves to
 * the list of files if successful.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cloud-Storage/operation/GetStorageFile
 */
export function getFiles(path: string = ""): Promise<AxiosResponse<GetFiles200Response>> {
	return signaloidApiClient.get(`/files/${path}`);
}

/**
 *	Get a URL for downloading a file.
 *
 * @param {string} path A POSIX relative path.
 * @returns	The download URL. (Throws exception on error.)
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cloud-Storage/operation/GetStorageFile
 */
export async function getFileDownloadURL(path: string) {
	/**
	 *	Explicitly not try..catch-ing this. Responsibility of caller.
	 */
	const res = await signaloidApiClient.get(`/files/${path}?download`);
	if (!Object.prototype.hasOwnProperty.call(res.data, "download_url")) {
		throw new Error("Error: non-error response does not contain download_url");
	}
	return res.data.download_url;
}

/*
 * Get a URL for uploading a file.
 *
 * @param {string} path A POSIX relative path.
 * @param {number} si File size of hte file you need to upload.
 * @returns	The upload URL. (Throws exception on error.)
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cloud-Storage/operation/PutStorageFile
 */
export async function putFileUploadURL(path: string, fileSizeInBytes: number) {
	/**
	 *	Explicitly not try..catch-ing this. Responsibility of caller.
	 */
	const res = await signaloidApiClient.put(`/files/${path}?size=${fileSizeInBytes}`);
	if (!Object.prototype.hasOwnProperty.call(res.data, "upload_url")) {
		throw new Error("Error: non-error response does not contain upload_url");
	}
	return res.data.upload_url;
}

/*
 * Create a directory
 *
 * @param {string} path A POSIX relative path.
 * @returns	response
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cloud-Storage/operation/PutStorageFile
 */
export async function putCreateDirectory(path: string) {
	return signaloidApiClient.put(`/files/${path}?directory`);
}
/**
 * Upload a file or create a directory.
 *
 * @param {string} path A POSIX relative path.
 * @param {string} directory The directory to create.
 * @returns {Promise<AxiosResponse>} The axios request promise.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/infrastructure/operation/putFile
 */
export async function putFile(path: string, directory: string): Promise<AxiosResponse> {
	return signaloidApiClient.put(`/files/${path}?directory=${directory}`);
}

/**
 *
 * @param {string} destination
 * @param {string} source
 * @returns {Promise<AxiosResponse<any>>} The axios request promise.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/infrastructure/operation/putFile
 */
export function copyUserFiles(destination: string, source: string): Promise<AxiosResponse<any>> {
	return signaloidApiClient.put(`/files/${destination}?copySource=${source}`);
}

/**
 *
 * @param path A POSIX relative path.
 * @param directory Boolean that confirms if relative path is a directory.
 * @param recursive Parameter that indicates if user wants to recursively delete a folder.
 * @returns {Promise<AxiosResponse>} When successful, the response contains.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cloud-Storage/operation/DeleteStorageFile
 */
export function deleteUserFiles(path: string, directory: boolean, recursive: boolean): Promise<AxiosResponse> {
	return signaloidApiClient.delete(`/files/${path}${directory ? "?directory" : ""}${recursive ? "&recursive" : ""}`);
}
