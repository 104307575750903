import * as Sentry from "@sentry/vue";
import axios, { AxiosError } from "axios";

export class TransactionError extends Error {
	transaction;
	constructor(message, name, transaction) {
		super(message);
		this.name = name ?? this.constructor.name;
		this.transaction = transaction;
	}
}

export function monitoringIdentifyUser(userID: string, userTier: string, userEmail?: string | undefined) {
	Sentry.setUser({
		id: userID,
		email: userEmail,
		// Potentially pass but first consider privacy:
		// username
	});
	Sentry.setTag("userTier", userTier);
}

export function monitoringForgetUser() {
	Sentry.setUser(null);
	Sentry.setTag("userTier", null);
}

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

type SentryExtras = {
	userMessage: string;
	[key: string]: unknown;
};

type SentryContext = {
	severity?: Sentry.SeverityLevel;
	extras?: SentryExtras;
	tags?: {
		[key: string]: Primitive;
	};
};

export function monitoringCaptureError(
	error: AxiosError | Error | unknown,
	transactionName: string,
	context: SentryContext = {}
) {
	context.severity = context.severity ?? "error";

	if (axios.isAxiosError(error)) {
		monitoringCaptureAxiosError(error, transactionName, context);
	} else if (error instanceof Error) {
		monitoringErrorHandler(error, transactionName);
	} else {
		/*
		 *	We will not take care of non-conforming errors in this path. Leave it as unhandled.
		 */
		throw new Error("monitoringCaptureError: argument 'error' is not AxiosError or Error" + error);
	}
}

export function monitoringCaptureAxiosError(axiosError: AxiosError, transactionName = "", context: SentryContext = {}) {

	/*
	 *	Supplement axiosError with extra information 
	 */
	let errorMessage: string = "";
	if (axiosError.response) {
		/*
		 *	We received a non-2xx response. (If it was 2xx it wouldn't be an error and this would not be running.)
		 */
		if (axiosError.response.status === 504) {
			errorMessage = "Gateway Timeout (504)";
		} else if (axiosError.response.status === 502 || axiosError.response.status === 500) {
			errorMessage = `Server error (${axiosError.response.status})`;
			context.severity = "fatal";	// This is fatal because it usually means that a Lambda is crashing.
		} else if (axiosError.response.status === 503) {
			errorMessage = "Service overloaded (503)";
		} else if (axiosError.response.status >= 500) {
			errorMessage = "Unknown Server Error (500)";
		} else if (axiosError.response.status === 401) {
			errorMessage = "Unauthorized (401)";
		} else if (axiosError.response.status === 403) {
			errorMessage = "Forbidden (403)";
		} else if (axiosError.response.status >= 400) {
			errorMessage = `Client Error (${axiosError.response.status} ${axiosError.response.statusText})`;
		} else {
			errorMessage = `Other HTTP Error (${axiosError.response.status} ${axiosError.response.statusText})`;
		}
	} else if (axiosError.request) {
		/*
		 *	There was no response. This can also be a client-side timeout.
		 */
		errorMessage = "No Response";
	} else {
		/*
		 *	Something happened in setting up the request that triggered an Error
		 */
		errorMessage = "Browser Error";
	}

	axiosError.message = `${errorMessage}: ${axiosError.message}`;

	if (!axiosError.name) {
		axiosError.name = "Network Error";
	}

	monitoringErrorHandler(axiosError, transactionName, context);
}

export function monitoringSetTransactionName(monitoringSetTransactionName: string) {
	Sentry.configureScope((scope) => {
		scope.setTransactionName(monitoringSetTransactionName);
	});
}

function monitoringErrorHandler(error: Error, transactionName = "", context: SentryContext = {}) {
	if (transactionName !== "") {
		error.message += ` (${transactionName})`;
	}

	/*
	 *	Only try to send events to Sentry if the integration has been set up.
	 */
	if (process.env.VUE_APP_SENTRY_ENVIRONMENT) {
		Sentry.withScope((scope) => {
			if (context?.severity) {
				scope.setLevel(context?.severity);
			}

			if (context.extras?.userMessage) {
				scope.setExtra("userMessage", context.extras?.userMessage);
			}

			if (context.tags) {
				scope.setTags(context.tags);
			}

			Sentry.captureException(error, scope);
		});
	} else {
		console.log(error);
	}
}
